import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: string | undefined;
  constructor(public http: HttpClient) {
    // this.url = environment.url;
    this.url = environment.url+'v1/api/';
  }

  save(apiRoute: string, body: any, isEdit: boolean = false) {
    if (isEdit) {
      return this.http.put(`${this.url + apiRoute}`, body, {});
    }
    return this.http.post(`${this.url + apiRoute}`, body, {});
  }

  post(apiRoute: string, body: any) {
    // let headers = new HttpHeaders();
    // headers = headers.set(
    //   'x-access-token',
    //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM5YTA4ZGFmLTRkYmMtNDRhZS1hNWQ2LThjZWExYzg2M2U1MCIsImlhdCI6MTY1OTgyODEyOCwiZXhwIjoxNjU5OTE0NTI4fQ.28s-H5FJBUbQE0ReSxL6vt_Mt2EiKpSwlftqYsEfdiQ'
    // );
    return this.http.post(`${this.url + apiRoute}`, body);
  }

  postData(url: any, data:any): Observable<any> {
    return this.http.post<any>(this.url + url, data);
  }

  get(apiRoute: string) {
    return this.http.get(`${this.url + apiRoute}`);
  }
  getcetak(apiRoute: string) {
    // return this.http.get('http://localhost/report/'+apiRoute);
    return this.http.get('https://report.nissaexpress.com/'+apiRoute);
  }

  put(apiRoute: string, body: any) {
    return this.http.put(`${this.url + apiRoute}`, body, {});
  }

  delete(apiRoute: string) {
    return this.http.delete(`${this.url + apiRoute}`, {});
  }

  rawPost(apiRoute: string, body: any) {
    return this.http.post(`${apiRoute}`, body, {
      // headers: this.getHttpHeaders(),
    });
  }

  downloadFile(apiRoute: string): any {
    return this.http.get(`${this.url + apiRoute}`, { responseType: 'blob' });
  }

  // getHttpHeaders(): HttpHeaders {
  //   return new HttpHeaders().set(
  //     'x-access-token',
  //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM5YTA4ZGFmLTRkYmMtNDRhZS1hNWQ2LThjZWExYzg2M2U1MCIsImlhdCI6MTY1OTQ4MDQxNiwiZXhwIjoxNjU5NTY2ODE2fQ.BvvlVCUF54U4-ZSqxxwiZpvIlZhUxaXJviBP9WeHTec'
  //   );
  // }
}
