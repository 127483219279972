<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Transaction</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="'/admin/app-tr-cleansings'">Cleansings</a></li>
            <li class="breadcrumb-item active"><a href="javascript:void(0)" >Form Cleansing</a></li>
          </ol>
        </div>
      </div>
    </div>

    <form [formGroup]="form">
      <div class="row">
        <!-- Shipping -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Shipping Information</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Connote</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="connote"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Ship Date <span class="text-danger">*</span></label
                    >
                    <p-calendar
                      formControlName="ship_date"
                      dateFormat="yy-mm-dd"
                      [showIcon]="true"
                      inputId="icon"
                      styleClass="w-100"
                    ></p-calendar>
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label"
                      >Shipper Code <span class="text-danger">*</span></label
                    >
                    <!-- [disabled]="action == 'view' || usersData.shipper && !isSuperadmin" -->
                    <p-dropdown
                      [options]="shippers"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [disabled]="action == 'view' || usersData.shipper && !isSuperadmin && isCleansings"
                      [showClear]="true"
                      styleClass="w-100"
                      [(ngModel)]="selectedShipper"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Shipper"
                      (onChange)="onDropdownChange($event, 'shipper_id')"
                      (onClear)="onDropdownClear('shipper_id')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedShipper">
                          <div>{{ selectedShipper.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label class="form-label">Shipper Reference</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="shipper_reff"
                    />
                  </div>
                </div>
                <!-- <div class="col-3">
                  <div class="form-group">
                    <label class="form-label">First Code Resi</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter First Code Resi"
                      formControlName="shipper_code_resi"
                    />
                  </div>
                </div> -->
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Shipper Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Shipper Reference"
                      formControlName="shipper_name"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label">Shipper Phone Number</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend" ngbDropdown>
                        <button
                          class="btn btn-primary dropdown-toggle d-flex justify-content-between"
                          type="button"
                          [disabled]="action == 'view' || usersData.shipper"
                          data-toggle="dropdown"
                          ngbDropdownToggle
                        >
                          <!-- <img
                            [src]="
                              'http://' +
                              selectedShipperPhoneNumberCountry?.flag
                            "
                            *ngIf="selectedShipperPhoneNumberCountry?.flag"
                            (error)="
                              selectedShipperPhoneNumberCountry.flag =
                                '/assets/images/qr.png'
                            "
                            width="30"
                            class="mr-2"
                          /> -->
                          <span *ngIf="selectedShipperPhoneNumberCountry?.phonecode">+{{
                            selectedShipperPhoneNumberCountry?.phonecode
                          }}</span>
                          <!-- </ng-template> -->
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <p-dropdown
                            [options]="countries"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            [disabled]="action == 'view' || usersData.shipper"
                            [showClear]="true"
                            [(ngModel)]="selectedShipperPhoneNumberCountry"
                            [ngModelOptions]="{standalone: true}"
                            styleClass="w-100"
                            placeholder="Select a Shipper Phonecode"
                            (onChange)="onDropdownChange($event, 'shipper_phone')"
                            (onClear)="onDropdownClear('shipper_phone')"
                          >
                            <ng-template pTemplate="selectedItem">
                              <div class="" *ngIf="selectedShipperPhoneNumberCountry">
                                <div>+{{ selectedShipperPhoneNumberCountry.phonecode }}</div>
                              </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              <div class="">
                                <div>+{{item.phonecode}} {{ item.name }}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="shipper_reff_phone_number"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4" *ngIf="!isAgen">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Vendor <span class="text-danger">*</span></label
                    >

                    <p-dropdown
                      [options]="vendors"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      styleClass="w-100"
                      [disabled]="action == 'view'"
                      [(ngModel)]="selectedVendors"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Vendor"
                      (onChange)="onDropdownChange($event, 'vendor_id')"
                      (onClear)="onDropdownClear('vendor_id')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedVendors">
                          <div>{{ selectedVendors.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Airwaybill <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="airwaybill"
                    />
                    <app-cleansing-form-errors
                      [control]="$any(form).get('airwaybill')"
                    ></app-cleansing-form-errors>
                  </div>
                </div>

                <!-- <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Airwaybill Vendor</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="awb_vendor"
                    />
                  </div>
                </div> -->

                <!-- <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Code resi Inbound</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="code_resi_inbound"
                    />
                  </div>
                </div> -->

                <!-- [disabled]="action == 'view' || selectedConsignee == null" -->
                <!-- <div class="col-3" *ngIf="selectedShipper">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Select Shipping Cost Country <span class="text-danger">*</span></label
                    >
                    <p-dropdown
                      [options]="selectedShipper.optionPrice"
                      optionLabel="price"
                      [filter]="true"
                      filterBy="price"
                      [showClear]="true"
                      styleClass="w-100"
                      [disabled]="action == 'view'"
                      [(ngModel)]="selectedPrice"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select Shipping Cost"
                      (onChange)="onDropdownChange($event, 'select_price')"
                      (onClear)="onDropdownClear('select_price')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedPrice">
                          <div>{{ selectedPrice.price }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.price }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <ngb-alert *ngIf="selectedConsignee == null" [dismissible]="false" type="info">
                    Select Consignee First Or Consignee Country.
                  </ngb-alert>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- Consignee Information -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Consignee Information</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label w-100">Search Consignee</label>

                    <p-autoComplete
                      [suggestions]="results"
                      [(ngModel)]="text"
                      [suggestions]="results"
                      [disabled]="action == 'view'"
                      [(ngModel)]="selectedConsignee"
                      (completeMethod)="filterValue($event)"
                      [ngModelOptions]="{ standalone: true }"
                      (onSelect)="
                        onAutoCompleteSelected($event, 'consignee_id')
                      "
                      field="name"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                    >
                      <ng-template let-value pTemplate="selectedItem">
                        <div class="flex space-x-2">
                          <div class="my-auto">{{ value.name }}</div>
                        </div>
                      </ng-template>

                      <ng-template let-source pTemplate="item">
                        <div class="flex space-x-2">
                          <div class="my-auto">{{ source.name }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="name"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee Reference
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="reff_name"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee Phone Number
                      <span class="text-danger">*</span></label
                    >
                    <div class="input-group mb-3">
                      <div class="input-group-prepend" ngbDropdown>
                        <button
                          class="btn btn-primary dropdown-toggle d-flex justify-content-between"
                          type="button"
                          [disabled]="action == 'view'"
                          data-toggle="dropdown"
                          ngbDropdownToggle
                        >
                          <!-- <img
                            [src]="
                              'http://' +
                              selectedPhoneNumber?.flag
                            "
                            *ngIf="selectedPhoneNumber?.flag"
                            (error)="
                              selectedPhoneNumber.flag =
                                '/assets/images/qr.png'
                            "
                            width="30"
                            class="mr-2"
                          /> -->
                          <span *ngIf="selectedPhoneNumber?.phonecode">+{{
                            selectedPhoneNumber?.phonecode
                          }}</span>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <p-dropdown
                            [options]="countries"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            [disabled]="action == 'view'"
                            [showClear]="true"
                            [(ngModel)]="selectedPhoneNumber"
                            [ngModelOptions]="{standalone: true}"
                            styleClass="w-100"
                            placeholder="Select a Phonecode"
                            (onChange)="onDropdownChange($event, 'phone_number')"
                            (onClear)="onDropdownClear('phone_number')"
                          >
                            <ng-template pTemplate="selectedItem">
                              <div class="" *ngIf="selectedPhoneNumber">
                                <div>+{{ selectedPhoneNumber.phonecode }}</div>
                              </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              <div class="">
                                <div>+{{item.phonecode}} {{ item.name }}</div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="phone_number"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee Country
                      <span class="text-danger">*</span></label
                    >
                    <p-dropdown
                      [options]="countries"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [disabled]="action == 'view'"
                      [showClear]="true"
                      styleClass="w-100"
                      (onChange)="getCost()"
                      [(ngModel)]="selectedCountryConsignee"
                      [ngModelOptions]="{standalone: true}"
                      (onChange)="onDropdownChange($event, 'consignee_country')"
                      (onClear)="onDropdownClear('consignee_country')"
                    >
                    <ng-template pTemplate="selectedItem">
                      <div class="" *ngIf="selectedCountryConsignee">
                        <div>{{ selectedCountryConsignee.name }}</div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="">
                        <div>{{ item.name }}</div>
                      </div>
                    </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-4" *ngIf="selectedCountryConsignee != null">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee City <span class="text-danger">*</span></label
                    >

                    <p-dropdown
                      [options]="cities"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [disabled]="action == 'view'"
                      [showClear]="true"
                      styleClass="w-100"
                      [(ngModel)]="selectedCityConsignee"
                      [ngModelOptions]="{standalone: true}"
                      (onChange)="onDropdownChange($event, 'consignee_city')"
                      (onClear)="onDropdownClear('consignee_city')"
                    >
                    <ng-template pTemplate="selectedItem">
                      <div class="" *ngIf="selectedCityConsignee">
                        <div>{{ selectedCityConsignee.name }}</div>
                      </div>
                    </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-4"></div>

                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee Address
                      <span class="text-danger">*</span></label
                    >
                    <textarea
                      class="form-control"
                      rows="4"
                      formControlName="address"
                    ></textarea>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Consignee Post Code
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="post_code"
                    />
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label">Consignee ARC Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="arc_number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Package Information -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Package Information</h4>
            </div>
            <div class="card-body">

              <ngb-alert *ngIf="selectedPrice == null && action != 'view'" [dismissible]="false" type="info">
                Select Shipping Cost First for calculate Total Payment.
              </ngb-alert>

              <div class="row">
                <div class="col-4" *ngIf="selectedShipper">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Select Shipping Cost Country <span class="text-danger">*</span></label
                    >
                    <p-dropdown
                      [options]="selectedShipper.optionPrice"
                      optionLabel="price"
                      [filter]="true"
                      filterBy="price"
                      [showClear]="true"
                      styleClass="w-100"
                      [disabled]="action == 'view'"
                      [(ngModel)]="selectedPrice"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select Shipping Cost"
                      (onChange)="onDropdownChange($event, 'select_price')"
                      (onClear)="onDropdownClear('select_price')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedPrice">
                          <div>{{ selectedPrice.weight }} KG - {{ selectedPrice.price }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>
                            {{ item.weight }} KG - {{ item.price }}
                          </div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <ngb-alert *ngIf="selectedConsignee == null" [dismissible]="false" type="info">
                    Select Consignee First Or Consignee Country.
                  </ngb-alert>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label"
                      >Total PCS of Packages
                      <span class="text-danger">*</span></label
                    >

                    <p-inputNumber
                      [disabled]="selectedPrice == null"
                      formControlName="total_pcs"
                      (onBlur)="getPayment()"
                    ></p-inputNumber>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Total Colly <span class="text-danger">*</span></label
                    >

                    <p-inputNumber
                      [disabled]="selectedPrice == null"
                      formControlName="total_colly"
                      (onBlur)="getPayment()"
                    ></p-inputNumber>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Customs Value (USD)
                      <span class="text-danger">*</span></label
                    >

                    <p-inputNumber
                      formControlName="customs_value"
                    ></p-inputNumber>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Actual Weight <span class="text-danger">*</span></label
                    >

                    <!-- <p-inputNumber
                      formControlName="actual_weight"
                    ></p-inputNumber> -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="weight"
                      />
                      <!-- formControlName="actual_weight" -->
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Charged Weight <span class="text-danger">*</span></label
                    >
                    <p-inputNumber formControlName="actual_weight"
                    [disabled]="selectedPrice == null" (onBlur)="getPayment()"></p-inputNumber>
                    <!-- <p-inputNumber formControlName="weight" -->
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Description </label>

                    <textarea
                      class="form-control"
                      rows="4"
                      formControlName="description"
                    ></textarea>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label">Notes </label>

                    <textarea
                      class="form-control"
                      rows="4"
                      formControlName="note"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div
                class="d-flex justify-content-between my-4 py-4 border-bottom border-light"
              >
                <h5 class="my-auto">List Item</h5>
                <button *ngIf="action != 'view'"
                  type="submit"
                  [disabled]="action == 'view'"
                  class="btn btn-info"
                  (click)="addPackageInfo()"
                >
                  Add item
                </button>
              </div>

              <ng-container *ngFor="let package of packages; let i = index">
                <div class="row">
                  <div class="p-2 col-md-2">
                    <div class="">
                      <label class="w-100"
                        >Type Item <span class="text-danger">*</span></label
                      >

                      <p-dropdown
                        [options]="typeItems"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true"
                        styleClass="w-100"
                        [disabled]="action == 'view'"
                        placeholder="Select a Type"
                        [(ngModel)]="package.selectedTypeItem"
                        [ngModelOptions]="{ standalone: true }"
                        (onChange)="dropdownPackageChange($event, 'type_id', i)"
                        (onClear)="dropdownPackageClear('type_id', i)"
                      >
                        <ng-template pTemplate="selectedItem">
                          <div class="" *ngIf="package.selectedTypeItem">
                            <div>{{ package.selectedTypeItem.name }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                          <div class="">
                            <div>{{ item.name }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="p-2 col-md-2">
                    <div class="form-group">
                      <label class="form-label w-100"
                        >Sub Type Item <span class="text-danger">*</span></label
                      >

                      <p-dropdown
                        [options]="package.subTypeSources"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true"
                        [disabled]="action == 'view'"
                        styleClass="w-100"
                        placeholder="Select a Type"
                        [(ngModel)]="package.selectedSubTypeItem"
                        [ngModelOptions]="{ standalone: true }"
                        (onChange)="
                          dropdownPackageChange($event, 'subtype_id', i)
                        "
                        (onClear)="onDropdownClear('subtype_id')"
                      >
                        <ng-template pTemplate="selectedItem">
                          <div class="" *ngIf="package.selectedSubTypeItem">
                            <div>{{ package.selectedSubTypeItem.name }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                          <div class="">
                            <div>{{ item.name }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="p-2 col-md-1">
                    <div class="">
                      <label class="w-100"
                        >Qty <span class="text-danger">*</span></label
                      >
                      <p-inputNumber
                        [(ngModel)]="package.qty"
                        [ngModelOptions]="{ standalone: true }"
                        styleClass=" w-100"
                        [disabled]="action == 'view'"
                        inputStyleClass="w-100"
                      ></p-inputNumber>
                    </div>
                  </div>
                  <div class="p-2 col-md-2">
                    <div class="">
                      <label class="form-label w-100"
                        >Currency <span class="text-danger">*</span></label
                      >

                      <p-dropdown
                        [options]="currencies"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true"
                        [disabled]="action == 'view'"
                        styleClass="w-100"
                        [(ngModel)]="package.selectedCurrency"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Select a Currency"
                        (onChange)="
                          dropdownPackageChange($event, 'currency', i)
                        "
                        (onClear)="onDropdownClear('currency')"
                      >
                        <ng-template pTemplate="selectedItem">
                          <div class="" *ngIf="package.selectedCurrency">
                            <div>{{ package.selectedCurrency.name }}</div>
                          </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                          <div class="">
                            <div>{{ item.name }}</div>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </div>
                  </div>

                  <div class="p-2 col-md-2">
                    <div class="">
                      <label class="w-100"
                        >Price <span class="text-danger">*</span></label
                      >
                      <p-inputNumber
                        [(ngModel)]="package.price"
                        [ngModelOptions]="{ standalone: true }"
                        styleClass=" w-100"
                        [disabled]="action == 'view'"
                        inputStyleClass="w-100"
                      ></p-inputNumber>
                    </div>
                  </div>

                  <div class="p-2 col-md-2">
                    <div class="">
                      <label class="form-label w-100">Description </label>

                      <textarea
                        class="form-control"
                        rows="4"
                        [disabled]="action == 'view'"
                        [(ngModel)]="package.desc"
                        [ngModelOptions]="{ standalone: true }"
                      ></textarea>
                    </div>
                  </div>

                  <div class="my-auto col-md-1">
                    <button *ngIf="action != 'view'"
                      type="submit"
                      class="btn btn-danger btn-xs"
                      (click)="removePackageInfo(i)"
                    >
                      remove item
                    </button>
                  </div>
                </div>
              </ng-container>

              <div class="d-flex justify-content-between my-4">
                <h5 class="my-auto">List Image</h5>
              </div>
              <ngx-dropzone
                style="z-index: 99"
                [disabled]="action == 'view'"
                (change)="onSelect($event)"
                [disabled]="action == 'view'"
                accept="image/jpeg,image/jpg,image/png,image/gif"
              >
                <ngx-dropzone-label>Click to add images</ngx-dropzone-label>
                <ngx-dropzone-image-preview
                  *ngFor="let f of files"
                  [removable]="true"
                  [file]="f"
                  (removed)="onRemove(f)"
                >
                  <ngx-dropzone-label
                    >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
                  >
                </ngx-dropzone-image-preview>
              </ngx-dropzone>

              <!-- Old Images Preview -->
              <div class="uk-child-width-1-4@m uk-grid-small uk-grid-match" *ngIf="filesOld.length > 0" uk-grid>
                <div *ngFor="let f of filesOld; let idx=index">
                  <div>
                    <div class="uk-card-default">
                        <div class="uk-card-media-top">
                            <img [src]="f.images" alt="" width="100">
                        </div>
                        <div *ngIf="action != 'view'">
                          <button class="uk-button uk-button-danger uk-width-1-1 uk-margin-small-bottom" (click)="removeImages(f, idx)"> <i class="fa fa-remove text-red"></i>Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- Payment Information -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Payment Information</h4>
            </div>
            <div class="card-body">

              <ngb-alert *ngIf="selectedPrice == null && action != 'view'" [dismissible]="false" type="info">
                Select Shipping Cost First for calculate Total Payment.
              </ngb-alert>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Payment Term <span class="text-danger">*</span></label
                    >

                    <p-dropdown
                      [options]="paymentType"
                      optionLabel="name"
                      [filter]="true"
                      [disabled]="action == 'view'"
                      filterBy="name"
                      [showClear]="true"
                      styleClass="w-100"
                      [(ngModel)]="selectedPaymentType"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Payment Type"
                      (onChange)="onDropdownChange($event, 'payment_term')"
                      (onClear)="onDropdownClear('payment_term')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedPaymentType">
                          <div>{{ selectedPaymentType.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Payment Term <span class="text-danger">*</span></label
                    >

                    <p-dropdown
                      [options]="currencies"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [showClear]="true"
                      [disabled]="action == 'view'"
                      styleClass="w-100"
                      [(ngModel)]="selectedCurrency"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Currency"
                      (onChange)="onDropdownChange($event, 'currency')"
                      (onClear)="onDropdownClear('currency')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedCurrency">
                          <div>{{ selectedCurrency.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Type Item <span class="text-danger">*</span></label
                    >
                    <p-dropdown
                      [options]="typeItems"
                      optionLabel="name"
                      [filter]="true"
                      [disabled]="action == 'view'"
                      filterBy="name"
                      [showClear]="true"
                      styleClass="w-100"
                      [(ngModel)]="selectedTypeItem"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Type"
                      (onChange)="onDropdownChange($event, 'type_id')"
                      (onClear)="onDropdownClear('type_id')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedTypeItem">
                          <div>{{ selectedTypeItem.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Sub Type Item <span class="text-danger">*</span></label
                    >

                    <p-dropdown
                      [options]="subTypeItems"
                      optionLabel="name"
                      [filter]="true"
                      filterBy="name"
                      [disabled]="action == 'view'"
                      [showClear]="true"
                      styleClass="w-100"
                      [(ngModel)]="selectedSubTypeItem"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Select a Type"
                      (onChange)="onDropdownChange($event, 'subtype_id')"
                      (onClear)="onDropdownClear('subtype_id')"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div class="" *ngIf="selectedSubTypeItem">
                          <div>{{ selectedSubTypeItem.name }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="">
                          <div>{{ item.name }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>

                <!-- <div class="col-6">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Shipping Cost <span class="text-danger">*</span></label
                    >
                    <p-inputNumber
                      formControlName="cost"
                      [disabled]="selectedPrice == null"
                      (onBlur)="getPayment()"
                    ></p-inputNumber>
                  </div>
                </div> -->
                <div class="col-6">
                  <div class="form-group w-100">
                    <label class="form-label w-100"
                      >Total Payment <span class="text-danger">*</span></label
                    >

                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      formControlName="payment"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <!-- Payment Information -->
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <!-- <button class="btn btn-info" *ngIf="action != 'view'" (click)="save('save')">Submit</button> -->

                <ng-container *ngIf="(isLoading$) === false">
                  <!-- <span class="indicator-label"> -->
                    <button class="btn btn-info" [disabled]="form.invalid" *ngIf="action != 'view'" (click)="save('save')">Submit</button>
                    <button class="btn btn-info" [disabled]="form.invalid" *ngIf="action != 'view'" (click)="save('print')">Submit & Print</button>
                    <!-- <button (click)="saveTutorial()" [disabled]="angForm.pristine || angForm.invalid" class="submit btn btn-primary">{{isCreated ? 'Submit':'Update'}}</button> -->
                  <!-- </span> -->
                </ng-container>
                <ng-container *ngIf="isLoading$">
                  <span class="indicator-progress" [style.display]="'block'">
                    Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </ng-container>

                <!-- <button class="btn btn-info" *ngIf="action != 'view'" (click)="save('print')">Submit & Print</button> -->
                <button class="btn btn-info" *ngIf="action == 'view'" (click)="cetak(dataCleansing)">Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
